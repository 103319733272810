<template>
  <div>
    <b-table
      bordered
      hover
      v-bind:items="coinList"
      v-bind:fields="fields"
      headVariant="light"
      show-empty
      style="font-size: 30px; width: 60vh"
    >
      <template v-slot:cell(image)="data">
        <div class="token-icon" v-bind:style="setIcon(data.value)" />
      </template>

      <template v-slot:empty>
        <b-spinner variant="primary" />
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "TheCoinList",
  components: {},
  props: {
    coins: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "image", label: "" },
        "symbol",
        { key: "amount", label: "Balance" },
      ],
    };
  },
  computed: {
    coinList() {
      return this.processCoinList(this.coins);
    },
  },
  methods: {
    setIcon(iconUrl) {
      return {
        "background-image": `url(${iconUrl})`,
      };
    },
    processCoinList(coins) {
      return coins
        .filter((coin) => (coin.balance || coin.amount) > 0)
        .map((coin) => {
          if (!coin.tokenId) {
            coin.image = require(`@/assets/images/bsv_icon.png`);
            coin.symbol = coin.protocol;
            coin.amount = coin.balance.toFixed(8);
          } else {
            coin.amount /= coin.satsPerToken;
            if (coin.decimals) {
              coin.amount = (coin.amount / 100).toFixed(2);
            }
          }
          return coin;
        });
    },
  },
  created() {},
};
</script>
