<template>
  <div id="cashier-page">
    <template v-if="isCashierAuthenticated">
      <b-row id="cashier-header" cols="1">
        <b-col sm="12">
          <h1 id="space-name-display">
            {{ spaceData.name }}
          </h1>
        </b-col>
      </b-row>
      <b-row id="cashier-content" cols="2">
        <b-col sm="8">
          <template>
            <TheCashierOfferList
              v-if="!isCheckingOut"
              v-on:offerSelected="moveOfferToSelected($event)"
            />
            <template v-else>
              <TheCashierInvoice
                v-bind="invoiceData"
                v-show="!isScanning"
                v-on:invoiceCreated="paymentId = $event"
              />
              <template v-if="isScanning">
                <div id="qr-container">
                  <TheQRScanner
                    v-on:decoded="captureContent($event)"
                    v-if="!isPaying"
                    v-on:stop="isScanning = false"
                  />
                  <Spinner v-else height="170px" />
                </div>
              </template>
              <div class="checkout-control-container checkout-return">
                <b-button
                  class="checkout-control"
                  variant="primary"
                  v-on:click="handleCheckoutFinish()"
                >
                  Back
                </b-button>
                <b-button
                  class="checkout-control"
                  variant="primary"
                  v-on:click="startScanning()"
                >
                  Scan&Pay
                </b-button>
              </div>
            </template>
          </template>
        </b-col>
        <b-col sm="4" id="order-details">
          <div id="open-input-container">
            <div id="open-input">
              <CurrencyInput
                v-bind:value="openValue"
                v-bind:options="currencyInputOptions"
                v-on:change="openValue = $event"
              />
              <IconButton
                class="open-value-control-button"
                icon="dash"
                variant="primary"
                v-on:click="openValue -= 1"
              />
              <IconButton
                class="open-value-control-button"
                icon="plus"
                variant="primary"
                v-on:click="openValue += 1"
              />
            </div>
          </div>
          <div id="basket-container">
            <TheCashierBasket
              v-bind:basket-contents="basketOffers"
              v-on:basketEdited="updateBasketOffers($event)"
              v-on:totalPriceChanged="isBasketValid = $event"
            />
          </div>
          <div class="checkout-control-container checkout-forward">
            <b-button
              class="checkout-control"
              variant="primary"
              v-bind:disabled="!isCheckoutReady"
              v-on:click="isCheckingOut = true"
            >
              Checkout
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-modal
        id="payment-alert-modal"
        title="Checkout was not finalized!"
        ok-title="Clear"
        cancel-title="Keep"
        button-size="lg"
        cancel-variant="primary"
        ok-variant="outline-primary"
        centered
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc
        v-on:ok="resetBasket"
      >
        Would you like to keep current list of items?
      </b-modal>
      <b-modal
        id="payment-success-modal"
        title="Checkout successful!"
        ok-only
        ok-title="Close"
        button-size="lg"
        size="lg"
        centered
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc
        lazy
      >
        <TheCashierReceipt v-bind:basket-contents="receiptData" />
      </b-modal>
      <b-modal
        id="pay-failed-modal"
        title="Paying with paper wallet failed!"
        ok-only
        ok-variant="danger"
        ok-title="Close"
        button-size="lg"
        size="lg"
        centered
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc
        lazy
      >
        Error while paying the invoice. Make sure balance is enough on the
        provided paper wallet
      </b-modal>
      <b-modal
        id="scan-failed-modal"
        title="Paying with paper wallet failed!"
        ok-variant="danger"
        ok-only
        ok-title="Close"
        button-size="lg"
        size="lg"
        centered
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc
        lazy
      >
        Scanned QR is not in a valid format <br />
        (it is not private key or Centi Encoded private key or it was encrypted
        for different space)
      </b-modal>
    </template>
  </div>
</template>

<script>
import TheCashierBasket from "@/components/cashier/TheCashierBasket";
import TheCashierOfferList from "@/components/cashier/TheCashierOfferList";
import CurrencyInput from "@/components/base/CurrencyInput";
import IconButton from "@/components/base/IconButton";
import { SPACE_ROUTE } from "@/constants/relative_api_routes";
import {
  CENTI_INVOICE_PAY_ROUTE,
  CENTI_INVOICE_STATUS_ROUTE,
} from "@/constants/relative_integrator_app_routes";
import storeAuthStatusGetters from "@/mixins/store_auth_status_getters";
import standalonePageAuth from "@/mixins/standalone_page_auth";
import TheCashierInvoice from "@/components/cashier/TheCashierInvoice";
import $ from "jquery";
import { PAYMENT_FINALIZED_STATES } from "@/constants/invoice_status";
import TheCashierReceipt from "@/components/cashier/TheCashierReceipt";
import TheQRScanner from "@/components/qr_scanner/TheQRScanner.vue";
import Spinner from "@/components/base/Spinner.vue";

export default {
  name: "Cashier",
  components: {
    Spinner,
    TheQRScanner,
    TheCashierReceipt,
    TheCashierInvoice,
    IconButton,
    CurrencyInput,
    TheCashierOfferList,
    TheCashierBasket,
  },
  mixins: [storeAuthStatusGetters, standalonePageAuth],
  data() {
    return {
      spaceData: null,
      spaceChoices: {},
      openValue: 0,
      isBasketValid: false,
      selectedOffers: [],
      isCheckingOut: false,
      isScanning: false,
      isPaying: false,
      privKey: "",
      paymentId: "",
      receiptData: [],
      invoiceStatusUrl:
        this.appConfig.BACKEND_TOOLS_URL + CENTI_INVOICE_STATUS_ROUTE,
      invoicePayUrl: this.appConfig.BACKEND_TOOLS_URL + CENTI_INVOICE_PAY_ROUTE,
      storeNamespace: "cashier",
    };
  },
  computed: {
    basketOffers() {
      const openOfferArray =
        this.openValue !== 0
          ? [[{ id: 0, name: "Open", base_price: this.openValue }, 1]]
          : [];
      return this.selectedOffers.concat(openOfferArray);
    },
    currencyInputOptions() {
      const currency = this.spaceData ? this.spaceData.base_currency : "CHF";
      return {
        currency,
        currencyDisplay: "code",
        locale: "en-US",
        useGrouping: false,
      };
    },
    spaceBackendUrl() {
      return (
        this.appConfig.BACKEND_API_URL +
        SPACE_ROUTE(this.$store.state.cashier.spaceId)
      );
    },
    invoiceData() {
      return {
        selectedOffers: this.selectedOffers,
        openValue: this.openValue,
        spaceData: this.spaceData,
        merchantId: this.$store.state.cashier.supplierId,
      };
    },
    isCashierAuthenticated() {
      return !!this.spaceData;
    },
    isCheckoutReady() {
      return this.isBasketValid || this.openValue > 0;
    },
  },
  watch: {
    isCheckingOut(newValue) {
      ["#order-details", "#cashier-header"].forEach((query) =>
        this.disableInputs(query, newValue)
      );
    },
  },
  methods: {
    moveOfferToSelected(offer) {
      const selectedOffersMapping = new Map(this.selectedOffers);
      const matchingOffer = Array.from(selectedOffersMapping.keys()).find(
        (selectedOffer) => selectedOffer.id === offer.id
      );
      let amount = 1;
      if (matchingOffer) {
        offer = matchingOffer;
        amount += selectedOffersMapping.get(offer);
      }
      selectedOffersMapping.set(offer, amount);
      this.selectedOffers = Array.from(selectedOffersMapping);
    },
    updateBasketOffers(payload) {
      if (payload.offer.id === 0) {
        this.openValue = 0;
      } else {
        const selectedOffersMapping = new Map(this.selectedOffers);
        if (payload.amount === 0) {
          selectedOffersMapping.delete(payload.offer);
        } else {
          selectedOffersMapping.set(payload.offer, payload.amount);
        }
        this.selectedOffers = Array.from(selectedOffersMapping);
      }
    },
    handleCheckoutFinish() {
      this.isCheckingOut = false;
      this.axios
        .get(this.invoiceStatusUrl, {
          params: { paymentId: this.paymentId },
        })
        .then((response) => {
          const paymentStatus = response.data;
          if (PAYMENT_FINALIZED_STATES.includes(paymentStatus)) {
            this.receiptData = [...this.basketOffers];
            this.$bvModal.show("payment-success-modal");
            this.resetBasket();
          } else {
            this.$bvModal.show("payment-alert-modal");
          }
        })
        .catch(() => {
          this.$bvModal.show("payment-alert-modal");
        });
    },
    disableInputs(parentQuery, value) {
      ["button", "input", "select"].forEach((controlElement) =>
        Array.from($(parentQuery).find(controlElement)).forEach(
          (element) => (element.disabled = value)
        )
      );
    },
    getSpace() {
      this.axios.get(this.spaceBackendUrl).then((response) => {
        this.spaceData = response.data;
      });
    },
    resetBasket() {
      this.selectedOffers = [];
      this.openValue = 0;
    },
    getAuthCredentials() {
      const spaceId = this.$route.query.space;
      const cashierId = this.$route.query.id;
      if (!cashierId || !spaceId) {
        return null;
      }
      return { cashierId, spaceId };
    },
    startScanning() {
      this.isScanning = true;
    },
    captureContent(content) {
      this.isPaying = true;

      this.axios
        .post(this.invoicePayUrl, {
          paymentId: this.paymentId,
          spaceId: this.$store.state.cashier.spaceId,
          privateKey: content,
        })
        .then(() => {
          this.isPaying = false;
          this.isScanning = false;
        })
        .catch((error) => {
          if (error.response.status == 400) {
            this.isPaying = false;
            this.$bvModal.show("scan-failed-modal");
          } else {
            this.isPaying = false;
            this.isScanning = false;
            this.$bvModal.show("pay-failed-modal");
          }
        });
    },
    onAuthSuccess() {
      this.getSpace();
    },
  },
};
</script>

<style lang="sass">
$open-input-height: 76px
$checkout-button-height: 98px
$cashier-header-height: 56px
$min-basket-list-height: calc(2 * 98px + 62px)

@use 'src/assets/styles/scrollbar'

#open-input-container
  height: $open-input-height

#basket-container
  height: calc(100% - #{$open-input-height} - #{$checkout-button-height})

.checkout-control-container
  height: $checkout-button-height

.checkout-return
  position: absolute
  bottom: 0
  left: 0

.checkout-forward
  text-align: right

#cashier-page
  margin-top: -56px
  padding: 0 20px
  height: calc(100vh - 20px)
  min-height: calc(#{$open-input-height} + #{$checkout-button-height} + #{$cashier-header-height} + #{$min-basket-list-height})

  #order-details
    border-left: 1px solid lightgray

  [class^="col-"], #order-details
    height: 100%

#cashier-header
  height: $cashier-header-height

  h1
    margin: 0

  #space-name-display
    text-align: center
    white-space: nowrap
    overflow-x: auto
    overflow-y: hidden

#cashier-content
  height: calc(100% - #{$cashier-header-height})

#open-input
  display: flex
  justify-content: center
  align-items: center
  font-size: 1.3rem
  height: fit-content

  label
    margin-bottom: 0
    margin-right: 9px

  .open-value-control-button
    margin: 0 9px

.checkout-control
  margin: calc(#{$checkout-button-height} - 62px) 3vw 0
  font-size: 2rem
  width: 180px

#payment-alert-modal, #payment-success-modal
  footer
    justify-content: space-evenly

#qr-container
  margin: 30px
  height: 60vh
  max-width: 70vw
  position: relative
  display: flex
  flex-direction: column
  justify-content: space-evenly
  align-items: center
</style>
